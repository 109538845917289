import {
  Alert,
  TextFieldProps,
  Box,
  Tooltip,
  Zoom,
} from '@mui/material';
import { TextField } from '@mui/material';
import { useFormikContext } from 'formik';
import React from 'react';

type FormikTextFieldProps = TextFieldProps & {
  name: string;
  tooltiptitle?: string;
  warningmessage?: string;
};

export default function FormikTextField(props: FormikTextFieldProps) {
  const { name, tooltiptitle = '', warningmessage } = props;
  const formik = useFormikContext();
  const meta = formik.getFieldMeta(name);
  const { value } = meta;

  return (
    <Tooltip
      title={tooltiptitle}
      TransitionComponent={Zoom}
      TransitionProps={{ timeout: 300 }}
      placement="bottom"
      arrow
    >
      <Box>
        <TextField
          {...formik.getFieldProps(name)}
          error={meta.touched && Boolean(meta.error)}
          helperText={meta.touched && meta.error}
          {...props}
        />
        {
          (warningmessage && value === '') && (
            <Alert severity="warning">{warningmessage}</Alert>
          )
        }
      </Box>
    </Tooltip>

  );
}
