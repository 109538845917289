import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Paper } from '@mui/material';

const FormContainer = styled(Box)(({ theme }) => ({
  minWidth: '400px',
  [theme.breakpoints.down('sm')]: {
    minWidth: 'auto',
  },
}));

const FormWrapper = styled(Paper)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

// Export styled components
export { FormContainer, FormWrapper };
