import * as Sentry from '@sentry/nextjs';
import React, { useState } from 'react';
import {
  Grid,
  Typography,
  Box,
  Paper,
  Button,
  useTheme,
} from '@mui/material';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';

import HeaderBar from '@/common/layouts/HeaderBar';
import DefaultLayout from '@/common/layouts/DefaultLayout';
import Division from '@/common/components/Division';
import { useMutation } from 'react-query';
import { useRouter } from 'next/router';
import { FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';
import FormikTextField from '@/common/components/forms/TextField';
import { appRoutes, storageKeys } from '@/common/config';
import { ApiError, LoginVars, LoginResponse } from '@/common/api/types';
import { storage } from '@/common/utils/storage';
import { useApiContext } from '@/common/contexts/ApiContext';
import useQueryParams from '@/common/hooks/useQueryParams';
import { useSnackbar } from 'notistack';
import CustomLink from '@/common/components/CustomLink';
import { isEnter } from '@/common/utils/common';
import { FormContainer, FormWrapper } from './styles';
import HeroBanner from '@/common/components/HeroBanner';
import CookieConsent from "react-cookie-consent";

interface LoginFormFields {
  email: string;
  password: string;
}

const validationSchema = yup.object({
  email: yup.string().email().required(),
  password: yup.string().min(8).required(),
});

const initialValues = {
  email: '',
  password: '',
};

function useLoginForm() {
  const { push } = useRouter();
  const { enqueueSnackbar } = useSnackbar();
  const { redirectTo } = useQueryParams();
  const { apiClient } = useApiContext();
  const formik = useFormik<LoginFormFields>({
    initialValues,
    validationSchema,
    onSubmit,
  });
  const loginMutation = useMutation<LoginResponse, ApiError | Error, LoginVars>(
    apiClient.users.login,
    {
      onSuccess,
      onError,
    },
  );

  function onSuccess(data: LoginResponse) {
    storage.setItem(storageKeys.accessToken, data.accessToken);
    storage.setItem(storageKeys.refreshToken, data.refreshToken);
    push(redirectTo ?? appRoutes.home.root);
  }
  function onError(error: ApiError | Error) {
    enqueueSnackbar(error.message, { variant: 'error' });
    Sentry.captureException(error);
    formik.setSubmitting(false);
  }
  function onSubmit(values: LoginFormFields) {
    loginMutation.mutate(values);
  }

  return {
    formik,
  };
}

export default function LoginPage() {
  const theme = useTheme();
  const { formik } = useLoginForm();
  const { push } = useRouter();
  const [showPassword, setShowPassword] = useState(false);

  const handleSignup = () => {
    push(appRoutes.auth.signup);
  };
  const handleEnter = (e: React.KeyboardEvent<Element>) => {
    if (isEnter(e) && !(formik.isSubmitting || !formik.isValid || !formik.dirty)) {
      formik.handleSubmit();
    }
  };

  return (
    <FormikProvider value={formik}>
      <HeaderBar hideSearch hideMenu />

      <HeroBanner />
      <CookieConsent>This website uses cookies to enhance the user experience.</CookieConsent>
      <DefaultLayout>
        <Grid
          container
          justifyContent="center"
          onKeyDown={handleEnter}
        >
          <FormWrapper>
            <FormContainer
              display="flex"
              flexDirection="column"
              padding={2}
            >
              <Typography variant="h6" align="center">
                Login
              </Typography>

              <Box margin={theme.spacing(1, 0)}>
                <FormikTextField
                  fullWidth
                  id="email"
                  name="email"
                  type="email"
                  label="Email"
                  variant="outlined"
                />
              </Box>
              <Box
                margin={theme.spacing(1, 0)}
                sx={{
                  position: 'relative',

                  '& .MuiInputBase-input': {
                    paddingRight: '40px',
                  },
                  '& .MuiButton-root': {
                    position: 'absolute',
                    top: '13px',
                    padding: 0,
                    right: '3px',
                    minWidth: 0,
                    zIndex: 3,
                  }
                }}
              >
                <FormikTextField
                  fullWidth
                  id="password"
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  label="Password"
                  variant="outlined"
                />
                <Button
                  onClick={() => {
                    setShowPassword((state: Boolean) => !state);
                  }}
                >
                  {
                    showPassword ? (<AiFillEyeInvisible size={30} color={'white'} />) : (<AiFillEye size={30} color={'gray'} />)
                  }
                </Button>
              </Box>

              <Box
                margin={theme.spacing(1, 0)}
                display="flex"
                justifyContent="center"
              >
                <CustomLink
                  href={appRoutes.auth.forgotPassword}
                  text="Forgot your password?"
                />
              </Box>

              <Box margin={theme.spacing(1, 0)}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  // disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
                  onClick={() => formik.handleSubmit()}
                >
                  Log in
                </Button>
              </Box>

              <Box margin={theme.spacing(1, 0)}>
                <Typography
                  variant="body1"
                  align="center"
                  sx={{
                    fontStyle: 'italic',
                  }}
                >
                  Or
                </Typography>
              </Box>

              <Button variant="outlined" color="primary" onClick={handleSignup}>
                Sign up
              </Button>
            </FormContainer>
          </FormWrapper>

          <Division />
        </Grid>
      </DefaultLayout>
    </FormikProvider >
  );
}
